<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Approval Cancel">
            <!-- <vs-tabs>
                <vs-tab @click="colorx = 'danger'" label="Approval Cancel"> -->
                    <div class="con-tab-ejemplo">
                        <approval-cancel ></approval-cancel>
                    </div>
                <!-- </vs-tab>
            </vs-tabs> -->
        </vx-card>
         
    </div>
</template>
<script>
import approvalCancel from "./approval-cancel/approval-cancel.vue"

export default {
    components:{
        'approval-cancel':approvalCancel,
    },
    data: () => ({
    }),
    watch: {
    },
    methods:{
    },
    mounted(){
        // this.showOption()
    }
}
</script>
